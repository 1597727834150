import { LabelWrapper } from './CoreLabelWithIcon.style'

import BankIcon from '../../assets/preview/bank.svg?react'
import CreditCardIcon from '../../assets/preview/credit-card.svg?react'
import NoteIcon from '../../assets/preview/note.svg?react'
import SendFormIcon from '../../assets/preview/send-from.svg?react'
import TimeZoneIcon from '../../assets/preview/timezone.svg?react'
import { tr } from 'mmfintech-commons'

interface ICoreLabelProps {
  title: string
  content: string
}

export const CoreLabelWithIcon = ({ title, content }: ICoreLabelProps) => {
  const localizationKey = 'CHECKOUT.PAYMENT.' + title.toUpperCase().split(' ').join('_') || 'CHECKOUT'

  return (
    <LabelWrapper>
      <div className='icon'>{LabelIcon(title)}</div>
      <div className='text'>
        <div className='title'>{tr(localizationKey, title)}</div>
        <div className='content'>{content}</div>
      </div>
    </LabelWrapper>
  )
}

const LabelIcon = (title: string) => {
  switch (title) {
    case 'holder name':
      return <SendFormIcon />
    case 'IBAN':
      return <CreditCardIcon />
    case 'Reference':
    case 'country code':
      return <NoteIcon />
    case 'time-zone':
      return <TimeZoneIcon />
    case 'account number':
    case 'bank name':
      return <BankIcon />
    default:
      return <BankIcon />
  }
}
